import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OpenFDAService } from './openfda.service';
import { HttpClientModule } from '@angular/common/http';
import { StatisticsComponent } from './statistics/statistics.component';
import { HeaderComponent } from './header/header.component';
import { LinksComponent } from './links/links.component';
import { BookComponent } from './books/book.component';
import { AboutComponent } from './about/about.component';
import { FoiaComponent } from './foia/foia.component';
import { ForumComponent } from './forum/forum.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CorporationComponent } from './corporation/corporation.component';
import { VideosComponent } from './videos/videos.component';

@NgModule({
  declarations: [
    AppComponent,
    StatisticsComponent,
    HeaderComponent,
    LinksComponent,
    BookComponent,
    AboutComponent,
    ForumComponent,
    FoiaComponent,
    CorporationComponent,
    VideosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  exports: [

    FormsModule,
    ReactiveFormsModule
  ],
  providers: [OpenFDAService],
  bootstrap: [AppComponent]
})
export class AppModule { }
