import { Component, ViewChild, ElementRef } from '@angular/core';



@Component({
  selector: 'videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent {

    constructor() {

      }


}