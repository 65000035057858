import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenFDAService {

  apiKey: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient){
    this.apiKey = "qtUlQb1BFGc4GepRZZLlyd8re29SFjp4QaJr4oix";
   }


  getReactionCount(drug, reaction) {
    return this.http.get<JSON>("https://api.fda.gov/drug/event.json?api_key=" + 
    this.apiKey + "&search=(patient.drug.openfda.generic_name:" + drug + ")+AND+(" +
     reaction + ")&count=patient.reaction.reactionmeddrapt.exact&limit=5");
  }

  getDetailedReports(drug, reaction){
    return this.http.get<JSON>("https://api.fda.gov/drug/event.json?api_key=" + 
    this.apiKey + "&search=(patient.reaction.reactionmeddrapt:%22" + reaction + 
    "%22)+AND+(patient.drug.openfda.generic_name:%22" + drug + "%22)&limit=100&skip=0");
  }

  getTorrentReactions(companyName: string): Observable<JSON>{
  return this.http.get<JSON>("https://api.fda.gov/drug/event.json?api_key=" + 
    this.apiKey + "&search=(" + encodeURI(companyName) + ")&limit=100&skip=0");
  }

  putComment(json: JSON) {
    console.log(json);
    return this.http.put<JSON>("http://http://50.116.7.106/", json, this.httpOptions);
  }
}
