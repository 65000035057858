import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { OpenFDAService } from '../openfda.service';

@Component({
  selector: 'forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {
  profileForm: FormGroup;

  constructor(private openfdaservice: OpenFDAService) {
    this.profileForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      emailAddress: new FormControl(''),
      comment: new FormControl('')
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    const put = this.openfdaservice.putComment(this.profileForm.value);
    put.subscribe((x) => console.log(x));
  }

}