<div id="linksContainer">
    Websites:
    <a class="link" href="http://www.thepillthatsteals.com/">
        the Pill That Steals Lives
    </a>
    <a class="link" href="https://missd.co/">
        MISSD
    </a>
    <a class="link" href="https://rxisk.org/">
        RxISK.org
    </a>
    <a class="link" href="https://www.drugawareness.org/">
        DRUGAWARENESS.ORG
    </a>
    <a class="link" href="https://www.cchr.org/">
        Citizens Commission on Human Rights
    </a>
    <a class="link" href="https://www.ssristories.net/">
        SSRIstories.NET
    </a>
    <a class="link" href="http://www.murdermeds.com/">
        MurderMeds.com
    </a>
    <a class="link" href="http://davidcarmichael.com/">
        davidcarmichael.com
    </a>
    <a class="link" href="https://www.woodymatters.com/">
        woodymatters.com
    </a>

    <a class="link" href="https://leoniefennell.wordpress.com/">
        Leonie Fenell mother of Shane Clancy
    </a>

    <a class="link" href="http://donfarber.com/">
        Donald J. Farber, attorney with experience in SSRI cases
    </a> 

    <a class="link" href="https://justiceseekers.com/meet-our-attorneys-andy-vickery/">
        Donald J. Farber, attorney with experience in SSRI cases
    </a> 


    <a class="link" href="http://antidepaware.co.uk/">
        AntiDepAware
    </a> 
</div>