<div #my_dataviz id="my_dataviz">

</div>
<div id='graphLabel'>Reports including reaction of homicide 
        <br/>by antidepressant brand name</div>

<div  *ngFor="let drug of drugArray">

  
    <div class="drugDiv">
        <h1 class="title">{{drug.toUpperCase()}}( {{genericToBrandName(drug)}})</h1>
        <div class="reactionCountDiv" *ngFor="let reaction of results[drug]" (click)="getDetailedReports($event)"
            attr.drug={{drug.toLowerCase()}} attr.reaction={{reaction.term}}>
            {{reaction.count}} results for {{reaction.term}}
            <div class="linkToDetailedReports">click for detailed reports</div>
            <div *ngIf="detailedReports[drug][reaction.term.toLowerCase()] !== undefined">
                <div class="report" *ngFor="let report of detailedReports[drug][reaction.term.toLowerCase()]">
                    <div class="reportInfo">
                    <div>
                        Safety Report ID: {{report.safetyreportid}}
                    </div>
                    <div>
                        Reciept Date: {{formatDate(report.receiptdate) | date}}
                    </div>
                    <div>
                        Patient Age: {{report.patient.patientonsetage}}
                    </div>
                    <div>
                        Patient Gender: 
                            <span *ngIf="report.patient.patientsex == '1'">male</span>
                            <span *ngIf="report.patient.patientsex == '2'">female</span>
                    </div>
                </div>
                    <div class="drugReaction">
                        DRUGS:
                        <div class="drug" *ngFor="let drug of report.patient.drug">
                            {{drug.medicinalproduct}}
                        </div>
                    </div>
                    <div class="drugReaction">
                        REACTIONS:
                        <div class="reaction" *ngFor="let reaction of report.patient.reaction">
                            {{reaction.reactionmeddrapt}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


