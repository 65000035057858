import { Component, ViewChild, ElementRef } from '@angular/core';
import { OpenFDAService } from '../openfda.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'foia',
  templateUrl: './corporation.component.html',
  styleUrls: ['./corporation.component.css']
})
export class CorporationComponent {
  reports: JSON[] = [];
  formValues = null;
  formGroup: FormGroup;

  constructor(private openFdaService: OpenFDAService, public fb: FormBuilder) {

    this.formGroup = this.fb.group({
      companyName: new FormControl('Scan or Enter'),
    });

    this.formGroup.valueChanges.subscribe((selectedValue) => {
      this.formValues = selectedValue;
      console.log(selectedValue);
    });

  }

  onSubmit() {
    console.log(this.formValues);
    this.openFdaService.getTorrentReactions(this.formValues).subscribe((response: JSON) => {
      this.reports.length = 0;
      response['results'].forEach((result) => {
        this.reports.push(result);
      });
    });
  }


}