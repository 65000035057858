import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('rendererContainer') rendererContainer: ElementRef;

  scene = new THREE.Scene();
  loader = new THREE.TextureLoader();
  bgTexture = this.loader.load('/assets/img/abstractBackground.jpg');

  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  renderer = new THREE.WebGLRenderer();
  controls = new OrbitControls(this.camera, this.renderer.domElement);
  width = window.innerWidth;
  height = window.innerHeight;

  constructor() {
    this.scene.background = this.bgTexture;
    this.camera.position.y = 0;
    this.camera.position.z = 10;
    this.scene.fog = new THREE.Fog(0x55AABB, 10, 50);
    const light = new THREE.DirectionalLight(0xAAFFFF, 0.75);
    light.position.set(-1, 2, 4);
    this.scene.add(light);
    //this.createPlane();
    this.camera.position.z = 5;
    this.createSprites(200);
  }


  createSprites(count?: number) {
    for (let i = 0; i < count; i++) {
      var spriteMap = new THREE.TextureLoader().load('/assets/img/serotonin.png');
      var spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap,       rotation: Math.PI * Math.random() });
      var sprite = new THREE.Sprite(spriteMaterial);
      sprite.position.x = (Math.random() * 100) - 50;
      sprite.position.y = (Math.random() * 100) - 50;
      sprite.position.z = (Math.random() * -100);
      sprite.scale.set(5, 5, 5);

      sprite.name = 'sprite';
      this.scene.add(sprite);
    }
  }

  createPlane() {
    const geometry = new THREE.PlaneGeometry(150, 250, 24, 24);
    const material = new THREE.MeshPhongMaterial({ map: this.bgTexture, color: 0x00ff00, side: THREE.DoubleSide });
    const object = new THREE.Mesh(geometry, material);
    this.scene.add(object);
    object.rotation.x = this.degrees_to_radians(90);
    object.position.y = -5;
    for (let i = 0; i < geometry.vertices.length; i++) {
      const scale = 2;
      geometry.vertices[i].z += (Math.random() * scale) - scale;
    }
  }

  ngAfterViewInit() {
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor('white', 0);
    this.controls.update();
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.animate();
  }

  degrees_to_radians(degrees: number) {
    const pi = Math.PI;
    return degrees * (pi / 180);
  }

  animate() {
    window.requestAnimationFrame(() => this.animate());
    this.scene.children.forEach((obj, num) => {
      let sign = num % 2 ? -1 : 1;
      const min = -50;
      if (obj.name === 'sprite' && obj.position.y > min) {
        const sprite = obj as THREE.Sprite;
        sprite.position.y -= 0.0007 * num;
        sprite.material.rotation += 0.0002 * num * sign;
      } else if (obj.name === 'sprite'){
        obj.position.y = -1 * min;
      }
    });
    this.renderer.render(this.scene, this.camera);
  };

}
