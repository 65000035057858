<div class="selectionMenu">Select the name of a pharmaceutical company to pull the adverse drug event records associated
    with
    that company from the FDA database using open FDA
    <div class="center">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <select class="dropdown" width="100" height="20" size="1" >
                <option value="Torrent Pharmaceuticals Limited">Torrent Pharmaceuticals Limited</option>
                <option value="Glaxo Smith Kline">Glaxo Smith Kline</option>
                <option value="Elli Lilly">Elli Lilly</option>
                <option value="Allergan">Allergan(Forest Laboratories)</option>
            </select>
            <button type="submit">Submit</button>
        </form>
    </div>
</div>

<div class="report" *ngFor="let report of reports">
    Safety Report ID: {{report.safetyreportid}}

    <pre>Reaction: {{report.patient.reaction[0].reactionmeddrapt | json}}</pre>
    <div *ngFor="let drug of report.patient.drug">
        Drug: {{drug.activesubstance}}
    </div>

</div>