import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { StatisticsComponent } from './statistics/statistics.component';
import { AppComponent } from './app.component';
import { LinksComponent } from './links/links.component';
import { BookComponent } from './books/book.component';
import { AboutComponent } from './about/about.component';
import { FoiaComponent } from './foia/foia.component';
import { ForumComponent } from './forum/forum.component';
import { CorporationComponent } from './corporation/corporation.component';
import { VideosComponent } from './videos/videos.component';


const routes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'book', component: BookComponent },
  { path: 'links', component: LinksComponent },
  { path: 'foia', component: FoiaComponent },
  { path: 'forum', component: ForumComponent },
  { path: 'corporation', component: CorporationComponent},
  { path: 'videos', component: VideosComponent},
  {path: '', component: StatisticsComponent},
  {path: '**', component: StatisticsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }
}


