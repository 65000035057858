import { Component, ViewChild, ElementRef } from '@angular/core';
import { OpenFDAService } from '../openfda.service';
import { interval } from 'rxjs/internal/observable/interval';
import { takeWhile } from 'rxjs/operators';

import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';


export class eventDiv extends HTMLDivElement {
  drug: string;
  reaction: string;

  constructor() {
    super();
  }
}

@Component({
  selector: 'statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent {
  [x: string]: any;
  title: string;
  results: Object;
  drugArray: string[];
  reactionArray: string[];
  detailedReports: Object;
  chartData = {};
  chartObservable: Subject<object>;

  brandName = {
    escitalopram: 'lexapro',
    bupropion: 'wellbutrin',
    fluoxetine: 'prozac',
    paroxetine: 'paxil',
    venlafaxine: "effexor",
    fluvoxamine: "luvox",
    citalopram: 'celexa',
    sertraline: 'zoloft',
    vortioxetine: 'trintellix',
    alprazolam: 'xanax',
    lorazepam: 'ativan',

  };

  @ViewChild('my_dataviz', { static: true }) donutChart?: ElementRef;

  constructor(private openfdaservice: OpenFDAService) {
    this.title = 'antidepressantstatistics';

    this.drugArray = ['escitalopram',
      'bupropion', 'fluoxetine',
      'paroxetine', 'sertraline',
      'venlafaxine', 'fluvoxamine',
      'citalopram', 'vortioxetine', 'alprazolam', 'lorazepam'];
    this.reactionArray = ['homicide', 'imprisonment',
      'homicidal ideation', 'completed suicide',
      'suicidal ideation', 'akathisia',
      'tardive dyskenisia', 'screaming',
      'gun shot wound', 'violence',
      'insomnia', 'aggression',
      'hostility'];
    this.results = new Object();
    this.detailedReports = new Object();
    this.drugArray.forEach((drug) => {
      this.detailedReports[drug.toLocaleLowerCase().replace(/ /gi, "")] = new Object();

    });
    let drugIndex = 0;
    const timer = interval(400);
    timer
    .pipe(takeWhile(val => val <= this.drugArray.length + 1))
    .subscribe((count) => {
      this.getChartData(drugIndex, 0);
      drugIndex++;
      if(drugIndex === this.drugArray.length){
          this.callNextReactionCount(0, -1);
      }
    });

    this.chartObservable = new Subject();

  }

  genericToBrandName(generic: string) {
    const lower = this.brandName[generic].toLocaleLowerCase();
    return lower.charAt(0).toUpperCase() + lower.substring(1);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.drugArray.forEach((drug) => {
      this.results[drug] = new Array();
      this.reactionArray.forEach(reaction => this.results[drug].push({ term: reaction, count: 0 }));
    })

    this.chartObservable.next(this.chartData);
    this.chartObservable.subscribe((data) => {
      this.generateChart(data);
    });
  }

  getDetailedReports(event: MouseEvent) {
    let div = event.srcElement as eventDiv;
    const drug = div.attributes['drug'].value;
    const reaction = div.attributes['reaction'].value;
    if (div.style.height !== '50vh') {
      div.style.height = '50vh';
      div.style.overflowY = 'scroll';
      div.style.overflowY = 'hide';

      this.openfdaservice.getDetailedReports(drug, reaction).subscribe(
        (reports: JSON) => {
          this.detailedReports[drug] = {};
          this.detailedReports[drug][reaction] = new Array();

          let i = 0;
          reports['results'].forEach((result) => {
            this.detailedReports[drug][reaction].push(result);
            i++;
          });
          this.detailedReports[drug][reaction].sort(this.sortReports);
        }
      );

    } else {
      div.removeAttribute('style');
      this.detailedReports[drug][reaction].length = 0;
    }


  }

  formatDate(date: string) {
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const year = date.slice(0, 4);
    const fdate = month + "-" + day + "-" + year;
    return Date.parse(fdate);
  }

  private sortReactionCount(a, b) {
    if (a.count < b.count) {
      return 1;
    } else if (a.count === b.count) {
      return 0;
    } else if (a.count > b.count) {
      return -1;
    }
  }

  private sortReports(a, b) {
    if (a.patient.drug.length < b.patient.drug.length) {
      return -1;
    } else if (a.patient.drug.length === b.patient.drug.length) {
      return 0;
    } else {
      return 1;
    }
  }

  private callNextReactionCount(drugIndex: number, reactionIndex: number) {
    const secondsCounter = interval(400);
    const subscription = secondsCounter.subscribe((x) => {
      reactionIndex++;
      if (reactionIndex == this.reactionArray.length) {
        reactionIndex = 0;
        drugIndex++;
      }
      if (drugIndex < this.drugArray.length) {
        if (reactionIndex < this.reactionArray.length) {
          let drug = this.drugArray[drugIndex];
          let reaction = this.reactionArray[reactionIndex];
          this.openfdaservice.getReactionCount(drug, reaction).subscribe(
            (json: JSON) => {
              const property = this.results[drug];
              property.find(x => x.term === reaction).count = json['results'][0].count;
              this.drugArray.forEach((drug) => {
                this.results[drug].sort(this.sortReactionCount);
              });
              //if (reaction === 'homicide') {
              //this.chartData[this.genericToBrandName(drug)] = json['results'][0].count;
              //this.chartObservable.next(this.chartData);
              //}
            });
        }
      }
      if (drugIndex === this.drugArray.length) {
        subscription.unsubscribe();
        this.chartObservable.complete();
        this.chartObservable.unsubscribe();
      }
    });
  }

  private getChartData(drugIndex: number, reactionIndex: number) {
    let drug = this.drugArray[drugIndex];
    let reaction = 'homicide';
    this.openfdaservice.getReactionCount(drug, reaction).subscribe(
      (json: JSON) => {
        //this.results[drug].find(x => x.term === reaction).count = json['results'][0].count;
        this.chartData[this.genericToBrandName(drug)] = json['results'][0].count;
      },
      (err) => { console.log(err) },
      () => {
        this.generateChart(this.chartData);
      }
    );
  }


  private generateChart(chartData: Object) {
     this.donutChart.nativeElement.innerHTML = '';
    // set the dimensions and margins of the graph
    const width = this.donutChart.nativeElement.clientWidth as number;
    const height = this.donutChart.nativeElement.clientHeight as number;

    // The radius of the pie plot is half the width or half the height (smallest one). I subtract a bit of margin.
    let radius =  height / 2;

    // append the svg object to the div called 'my_dataviz'
    let svg = d3.select("#my_dataviz")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    let data = chartData;

    // set the color scale
    let color = d3.scaleOrdinal()
      .domain(["a", "b", "c", "d", "e", "f", "g", "h"])
      .range(d3.schemeDark2);

    // Compute the position of each group on the pie:
    let pie = d3.pie()
      .sort(null) // Do not sort group by size
      .value(function (d) { return d.value; })
    let data_ready = pie(d3.entries(data))

    // The arc generator
    let arc = d3.arc()
      .innerRadius(radius * 0.2)         // This is the size of the donut hole
      .outerRadius(radius * 0.7)

    // Another arc that won't be drawn. Just for labels positioning
    let outerArc = d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9)

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d) { return (color(d.data.key)) })
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .style("opacity", 0.7)

    // Add the polylines between chart and labels:
    svg
      .selectAll('allPolylines')
      .data(data_ready)
      .enter()
      .append('polyline')
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr('points', function (d) {
        let posA = arc.centroid(d) // line insertion in the slice
        let posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
        let posC = outerArc.centroid(d); // Label position = almost the same as posB
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC]
      })

    // Add the polylines between chart and labels:
    svg
      .selectAll('allLabels')
      .data(data_ready)
      .enter()
      .append('text')
      .text(function (d) { return d.data.key + " " + d.data.value })
      .attr('transform', function (d) {
        let pos = outerArc.centroid(d);
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return 'translate(' + pos + ')';
      })
      .style('text-anchor', function (d) {
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        return (midangle < Math.PI ? 'start' : 'end')
      })
  }

}