<div class="description">
    <p>A freedom of information act request was issued to the Food and Drug Administration on September 29th 2019. All
        records of violence related reactions to Lexapro, Escitalopram, and Escitalopram Oxalate were requested
        including
        the full narratives of the reports. As a
        response the FDA
        this .pdf
        report with limited details of reactions to the drug.</p>

    <p>The document lists 36 reactions of screaming, 44 cases of homicidal ideation, 33 fully completed homicides, and
        1,611 fully completed suicides.</p>

    <p>We emailed the FDA on December 22nd 2019 informing them that according to federal government law they are
        required
        to return the reports with the full narratives included. </p>
    <p>After several emails to the FDA we recieved a phone call from the FOIA director. She stated that it takes 6 to 9
        months to process requests of over 12 records as they are classified as complex requests. She suggested
        submitting another request for less than 12 records.
    </p>
    <p>We submitted 3 more requests including requests for the full transcripts and videos of the 1991, Feb 2004, Sept
        2004, and Dec 2004 hearings the FDA has held on this subject</p>
    <p>We received a second phone call from the director at FOIA stating that they had fulfilled the second request but
        that the request would be classified as complex if she fullfilled the others and
        delayed further. She stated that we would be able to submit another request next month they have to be separated
        by 30 days.
    </p>


    <ul class="statistics">the initial response lists the following statistics
        <li>33 homicides</li>
        <li>44 homicidal ideation</li>
        <li>1611 completed suicide</li>
        <li>1089 suicidal ideation</li>
        <li>36 screaming</li>
        <li>539 hallucination</li>
        <li>15 violence-related-symptom</li>
        <li>392 aggression</li>
    </ul>


    <ul class="statistics">
        <li>12 sexual abuse</li>
        <li>16 acute psychosis</li>
        <li>168 mania</li>
        <li>248 intentional self-injury</li>
        <li>94 memory impairment</li>
        <li>92 Gun Shot Wound</li>
        <li>29 Imprisonment</li>
        <li>17 Judgement Impaired</li>
        <li>114 psychotic disorder</li>
        <li>252 Abnormal Behaviour</li>
    </ul>

    <p>After submitting a total of 9 FOIA requests to the FDA they issued a response the second document below:</p>

</div>

<object class="resultsPdf" data="/assets/pdf/Lexapro_FDA_Foia.pdf" type="application/pdf" class="foiaReport">
    <p>Your browser does not support PDFs.
        <a href="http://antidepressantstatistics.com/assets/Lexapro_FDA_Foia.pdf">Download the PDF</a>.</p>
</object>

<object class="resultsPdf" data="/assets/pdf/20000681_enc_400.pdf" type="application/pdf" class="foiaReport">
    <p>Your browser does not support PDFs.
        <a href="http://antidepressantstatistics.com/assets/20000681_enc_400.pdf">Download the PDF</a>.</p>
</object>