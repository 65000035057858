import { Component, ViewChild, ElementRef } from '@angular/core';



@Component({
  selector: 'foia',
  templateUrl: './foia.component.html',
  styleUrls: ['./foia.component.css']
})
export class FoiaComponent {

    constructor() {

      }


}