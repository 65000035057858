<div id="rendererContainer" #rendererContainer></div>
<div class="col-md-4 text-center" id="navBar">
    <img style="left: 10vw" class="serotoninIcon" src="/assets/img/serotonin.svg" />
    <div id="mainTitle">AntiDepressant Statistics</div>
    <div class="navdiv" href="https://open.fda.gov/about/">
        All statistics are pulled from FAERS, the FDA's database of
        adverse drug events using OpenFDA
    </div>
    <img style="right: 10vw" class="serotoninIcon" src="/assets/img/serotonin.svg" />
</div>


<div id='internalLinks'>
    <a class='internalLink' routerLink="about">About</a>
    <a class='internalLink' routerLink="/">Home</a>
    <a class='internalLink' routerLink="book">Books</a>
    <a class='internalLink' routerLink="links">Links</a>
    <a class='internalLink' routerLink="foia">FDA FOIA request</a>
    <a class='internalLink' routerLink="forum">Comments</a>
    <a class='internalLink' routerLink="corporation">Stats by Company</a>
    <a class='internalLink' routerLink="videos">Videos</a>

</div>

<div id="externalLinks"> <a class='externalLink' href="https://open.fda.gov/about/">openFDA</a>
    <a class='externalLink'
        href="https://www.fda.gov/drugs/questions-and-answers-fdas-adverse-event-reporting-system-faers/fda-adverse-event-reporting-system-faers-public-dashboard">FAERS</a>
    <a class="externalLink" href="https://rxisk.org/">RxISK.org</a>
    <a class='externalLink' href="https://SSRIstories.NET">SSRIstories.NET</a>
    <a class='externalLink' href="https://www.drugawareness.org/">DrugAwareness.org</a>
    <a class="externalLink" href="http://www.murdermeds.com/">MurderMeds.com</a>
</div>

<div id="conclusion">
    <div>Conclusion: <br />
        <a href="https://www.ncbi.nlm.nih.gov/pubmed/21655992">
            Dopaminergic agonists, benzodiazepines and serotoninergic antidepressants are the main
            pharmacological classes able to induce aggressive behaviour. This study also emphasises
            the putative role of other drugs less known to be involved in such ADR.
        </a>
    </div>

    <div>
        Conclusions: <br />
        <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0015337">
            Acts of violence towards others are a genuine and serious adverse drug event
            associated with a relatively small group of drugs. Varenicline, which increases the availability
            of dopamine, and antidepressants with serotonergic effects were the most strongly and consistently
            implicated drugs.
        </a>
    </div>
</div>