<form class="form" (ngSubmit)="onSubmit()" [formGroup]="profileForm">
    <div class="desc">
        <p>We would love to hear from others who have experienced adverse drug events.</p>
        <p>The Name and email address are private and will not be displayed.</p>
        <p>They are also not required so just leave them blank if you want to remain anonymous</p>
    </div>
    <label>
        First Name:
        <input type="text" formControlName="firstName">
    </label>

    <label>
        Last Name:
        <input type="text" formControlName="lastName">
    </label>

    <label>email address:
        <input type="text" formControlName="emailAddress" />
    </label>

    <label>comment
        <textarea name="comment" cols="75" rows="15" charswidth="30" formControlName="comment"></textarea>
    </label>

    <input type="submit" value="Submit">
</form>