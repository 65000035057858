<div class="centerColumn">
    <iframe class="centerVideo" src="https://www.youtube.com/embed/SDq6eQJ9KJo" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/P4Gp_w-UD3k" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/SBJfZtB_3cc" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/M1rAAPklkGQ" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/Q3Lcpc4k_FU" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/qH9PUnPjqEM" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/Y9dMtz7BwLc" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/TG-eiajFQTs" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/luKsQaj0hzs" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe class="centerVideo" src="https://www.youtube.com/embed/0F_kLbtnWWM" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</div>