<div class="container">
    <div id="bookContainer">
        Books:
        <a class="book" href="https://www.amazon.com/dp/0916095592/ref=cm_sw_em_r_mt_dp_U_bjAIDbXQR0S7J">
            Prozac, Panacea or Pandora?
            <br />-Ann Blake Tracy, PhD
        </a>
        <a class="book" href="https://www.amazon.com/dp/1138443476/ref=cm_sw_em_r_mt_dp_U_ErAIDbQ7XA82C">
            Deadly Medicines and Organized Crime, How big pharma has corrupted healthcare
            <br />-Peter C Gotzsche
        </a>
        <a class="book" href="https://www.amazon.com/dp/031256550X/ref=cm_sw_em_r_mt_dp_U_7uAIDbVYQNAT3">
            Medication Madnesss, the role of psychiatric drugs in cases of Violence, Suicide, and Crime
            <br />-Peter R. Breggin, M.D.
        </a>
        <a class="book" href="https://www.amazon.com/dp/0520270983/ref=cm_sw_em_r_mt_dp_U_zAAIDb5V5CR6F">
            PHARMAGEDDON
            <br />-David Healy
        </a>
        <a class="book" href="https://www.amazon.com/dp/1421412063/ref=cm_sw_em_r_mt_dp_U_rzAIDbWQ5WG8G">
            Bipolar Disorder, a guide for patients and families 3rd edition,
            <br />-Francis Mark Mondimore, M.D.
        </a>
        <a class="book" href="https://www.amazon.com/dp/1786061333/ref=cm_sw_em_r_mt_dp_U_-ZLIDb2E30BGC">
            The Pill That Steals Lives
            <br />-Katinka Blackford Newman
        </a>
    </div>

    <div id="bookContainer">
        Scientific Research Papers:
        <a class="book" href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0015337">
            Prescription Drugs Associated with Reports of Violence Towards Others
            <br />-Thomas J. Moore ,Joseph Glenmullen,Curt D. Furberg
        </a>
        <a class="book" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1564177/">
            Antidepressants and Violence: Problems at the Interface of Medicine and Law
            <br />-Dr. David Healy, Andrew Herxheimer, and David B Menkes
        </a>
        <a class="book" href="https://www.sciencedirect.com/science/article/pii/S1752928X16300051">
            The relevance of cytochrome P450 polymorphism in forensic medicine and akathisia-related violence and
            suicide
            <br />-Selma J.M.Eikelenboom-Schieveld, YolandeLucire, James C.Fogleman
        </a>
        <a class="book" href="https://www.mpg.de/8426203/escitalopram-brain">
            Escitalopram alters functional brain architecture in a matter of hours
            <br />-Dr. Julia Sacher
        </a>

        <a class="book" href="https://www.bmj.com/content/358/bmj.j3697/rr-4">
            Antidepressants increase the risk of suicide, violence and homicide at all ages
            <br />-Peter C Gøtzsche
        </a>

        <a class="book" href="https://link.springer.com/article/10.1007%2Fs00228-011-1067-7">
            Prescribed drugs and violence: a case/noncase study in the French PharmacoVigilance Database
            <br />-Nadège RouveHaleh BagheriNorbert TelmonAtul PathakNicolas FranchittoLaurent SchmittDaniel
            RougéMaryse, Lapeyre-MestreJean-Louis Montastruc, the French Association of Regional PharmacoVigilance
            Centres
        </a>

        <a class="book" href="http://psychrights.org/research/digest/AntiDepressants/Questions.pdf">
            Raising Questions about AntiDepressants
            <br/>David O. Antonuccio, William G. Dantona, Garland Y. DeNelskyb,
            Roger P. Greenberg, James S. Gordond
        </a>
    </div>
</div>