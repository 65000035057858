
<div id="disclaimer">
    <p class='noClick'>The Food and Drug Administration (FDA or USFDA) is a federal government agency of the United
        States Department of Health and
        Human Services</p>

    <p class='noClick'>These statistics are pulled directly from the Federal Adverse Event Reporting System. They are
        provided by the Food and Drug Administration via the Open FDA database endpoints. No manipulation of the
        statistics has taken place.</p>

    <p class='noClick'>The FDA states that less than 1% of adverse drug events get reported. So this is a very small
        subset of the number of people that have been harmed by Selective Serotonin Reuptake Inhibitors and other forms
        of psychiatric medication. the incidents themselves are referred to as Adverse Drug
        Events and are
        sent to the FDA by doctors and healthcare professionals for statistical purposes.</p>

    <p class='noClick'>I don't make the statistics I just display what has been reported to the FDA already exactly as
        it was reported. </p>


    <div class='noClick'>
        <p class='noClick'><a id="safetyReview" href="./pdf/Lexapro-Pediatric-Safety-Review-and-Utilization-Review.pdf">
                From the FDA's Pediatric Postmarketing Pharmacovigilance and Drug Utilization Review(Escitalopram),
                published November 22, 2017</a> </p>

        <p class='noClick'><q>Reviewer’s Comments: Aggression collectively refers to, unless otherwise specified, suicide,
            homicide, and other forms of violence. The difference between these forms of violence is their
            intended target. Escitalopram is labeled in Section 5, Warnings and Precautions, for
            “anxiety, agitation, panic attacks, insomnia, irritability, hostility, aggressiveness,
            impulsivity, akathisia (psychomotor restlessness), hypomania, and mania.” </q></p>
    </div>

    <p class='noClick'>Each adverse drug event has a case ID which can be used to file a freedom of information act
        request for the full report of the incident</p>
</div>